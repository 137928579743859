.feature-dialog-modal .modal-content,
.feature-dialog-modal .accordion-body {
  background-color: rgb(23, 28, 41);
  color: white;
}

.feature-dialog-modal .accordion-button {
  background-color: rgb(66, 83, 127);
  border: 1px solid white;
  color: white;
}

.feature-dialog-modal .toolbox-container div:hover,
.feature-dialog-modal .feature-property-info:hover {
  background-color: rgb(23, 28, 41);
  cursor: default;
}
