/* STYLES FOR REACT-BURGER-MENU  */

/* Position and sizing of burger button */

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 36px;
  margin-top: 72px;
}

/* Color/shape of burger icon bars */

.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/

.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */

.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */

.bm-cross {
  background: #bdc3c7;
}

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */

.bm-menu-wrap {
  top: 72px;
  height: calc(100% - 72px) !important;
  right: 0px;
  width: 300px;
}

/* General sidebar styles */

.bm-menu {
  background: rgba(55, 58, 71, 0.8);
  padding: 16px;
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
}

/* Morph shape necessary with bubble or elastic */

.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */

.bm-item-list {
  color: #373a47;
  padding: 16px 0 0 0;
  font-size: 12px;
}

/* Individual item */

.bm-item {
  display: inline-block;
  padding: 4px;
}

/* Styling of overlay */

.bm-overlay {
  top: 0px;
  height: 0px;
  visibility: hidden;
  background: rgba(0, 0, 0, 0.3);
}

.btn-light {
  margin-right: 8px;
}
